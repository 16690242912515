<template>
  <svg
    width="14"
    height="4"
    viewBox="0 0 14 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.99992 0.333252C1.08325 0.333252 0.333252 1.08325 0.333252 1.99992C0.333252 2.91658 1.08325 3.66658 1.99992 3.66658C2.91658 3.66658 3.66658 2.91658 3.66658 1.99992C3.66658 1.08325 2.91658 0.333252 1.99992 0.333252ZM11.9999 0.333252C11.0833 0.333252 10.3333 1.08325 10.3333 1.99992C10.3333 2.91658 11.0833 3.66658 11.9999 3.66658C12.9166 3.66658 13.6666 2.91658 13.6666 1.99992C13.6666 1.08325 12.9166 0.333252 11.9999 0.333252ZM6.99992 0.333252C6.08325 0.333252 5.33325 1.08325 5.33325 1.99992C5.33325 2.91658 6.08325 3.66658 6.99992 3.66658C7.91658 3.66658 8.66658 2.91658 8.66658 1.99992C8.66658 1.08325 7.91658 0.333252 6.99992 0.333252Z"
      fill="#54718E"
    />
  </svg>
</template>
